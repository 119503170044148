import React from "react"
import { Layout } from "../components"
import { Link, useStaticQuery, graphql } from "gatsby"

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
    }
  `)

  return (
    <Layout>
      <div className="flex flex-col items-center content-center flex-wrap">
        <div id="logo" className="mt-32 mb-6">
          <img
            className="h-64"
            src={data.logo.publicURL}
            alt="Big's Axe Throwing"
          />
        </div>
        <div className="mb-6 text-gray-900 text-center font-sans text-xl">
          <h3>
            Oops! Sorry about that, we couldn't find the page you were looking
            for.
          </h3>
          <span className="opacity-50">Take me back to </span>
          <Link className="border-b" to="/">
            Big's Axe Throwing
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
